
import Vue, { PropType } from "vue";

import Vacancy from "@/components/vacancy.vue";
import { WaitingMinutesRange } from "@/components/vacancy.vue";

export default Vue.extend({
  name: "card-gate",
  components: {
    Vacancy,
  },
  props: {
    gate: { type: String as () => "a" | "b" | "c" | "d", required: true },
    vacancyState: { type: String, required: true },
    waitingMinutesRange: { type: Object as PropType<WaitingMinutesRange>, required: false },
    language: { type: String, required: true },
  },
});
