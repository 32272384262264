
import Vue from "vue";

import SectionMap from "@/components/section-map.vue";
import CardGate from "@/components/card-gate.vue";
import { getVacancyListByPlaceIdHashList, ResponsePlaceVacancy } from "@/api/signage.adapter.api";
import { WaitingMinutesRange } from "@/components/vacancy.vue";

interface PlaceVacancy {
  placeIdHash: string;
  vacancyStatus: string;
  waitingMinutesRange?: WaitingMinutesRange;
}

export default Vue.extend({
  name: "views-top",
  components: {
    SectionMap,
    CardGate,
  },
  data() {
    return {
      placeVacancyList: [] as PlaceVacancy[],
      placeIdHashList: [] as string[],
      language: "ja" as "ja" | "en",
      timer: undefined as number | undefined,
      text: {
        ja: {
          introduction: {
            main: "羽田空港第2ターミナル(国内線)保安検査場の<br />待ち時間と搭乗口までの所要時間をご覧いただけます",
            caution: "保安検査場はご出発時刻の20分前までにご通過ください",
          },
          title: "保安検査場の混雑状況",
          caution: "保安検査場での通過にかかる目安時間を掲示しております",
        },
        en: {
          introduction: {
            main: "[Haneda Airport Terminal 2] Information on transit time at the<br />security checkpoint and the required time to boarding gate.",
            caution: "Please pass through security at least 20 minutes prior to the<br />departure time.",
          },
          title: "Waiting time for Security Checkpoint",
          caution: "Display of approximate time required<br class='hide-when-wide' /> for passing through security.",
        },
      },
    };
  },
  methods: {
    async fetchPlaceVacancyList(): Promise<void> {
      const responsePlaceVacancyList: ResponsePlaceVacancy[] = await getVacancyListByPlaceIdHashList(
        this.placeIdHashList,
      ).catch((_) => []);
      this.placeVacancyList = responsePlaceVacancyList.map((responsePlaceVacancy: ResponsePlaceVacancy) => {
        return {
          placeIdHash: responsePlaceVacancy.placeIdHash,
          vacancyStatus: responsePlaceVacancy.status,
          waitingMinutesRange: this.getWaitingMinutesRangeByVacancyText(
            responsePlaceVacancy.displayVacancyText.languageList[this.language],
          ),
        };
      });
    },
    placeVacancyByGate(gate: "a" | "b" | "c" | "d"): PlaceVacancy | undefined {
      const placeIdHashGateMap: Record<"a" | "b" | "c" | "d", string> = {
        a: process.env.VUE_APP_PLACE_ID_HASH_GATE_A,
        b: process.env.VUE_APP_PLACE_ID_HASH_GATE_B,
        c: process.env.VUE_APP_PLACE_ID_HASH_GATE_C,
        d: process.env.VUE_APP_PLACE_ID_HASH_GATE_D,
      };
      return this.placeVacancyList.find(
        (placeVacancy: PlaceVacancy) => placeIdHashGateMap[gate] === placeVacancy.placeIdHash,
      );
    },
    vacancyStateByGate(gate: "a" | "b" | "c" | "d"): string {
      const placeVacancy: PlaceVacancy | undefined = this.placeVacancyByGate(gate);
      return placeVacancy !== undefined ? placeVacancy.vacancyStatus : "vacancy";
    },
    waitingMinutesRangeByGate(gate: "a" | "b" | "c" | "d"): WaitingMinutesRange | undefined {
      const placeVacancy: PlaceVacancy | undefined = this.placeVacancyByGate(gate);
      return placeVacancy !== undefined ? placeVacancy.waitingMinutesRange : undefined;
    },
    getWaitingMinutesRangeByVacancyText(vacancyText: string | undefined): WaitingMinutesRange | undefined {
      if (vacancyText === undefined) {
        return;
      }
      const includedNumberStrList: string[] = vacancyText
        .replace(/[^0-9]/g, "t") // なんでもいい
        .split("t");
      const waitingMinutes = !isNaN(parseInt(includedNumberStrList[0]))
        ? parseInt(includedNumberStrList[0])
        : undefined;
      const nextWaitingMinutes = !isNaN(parseInt(includedNumberStrList[1]))
        ? parseInt(includedNumberStrList[1])
        : undefined;
      return waitingMinutes !== undefined && nextWaitingMinutes !== undefined
        ? {
            from: waitingMinutes,
            to: nextWaitingMinutes,
          }
        : undefined;
    },
  },
  async mounted() {
    const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
    this.language = urlSearchParams.get("lang") === "ja" ? "ja" : "en";
    this.placeIdHashList = [
      process.env.VUE_APP_PLACE_ID_HASH_GATE_A,
      process.env.VUE_APP_PLACE_ID_HASH_GATE_B,
      process.env.VUE_APP_PLACE_ID_HASH_GATE_C,
      process.env.VUE_APP_PLACE_ID_HASH_GATE_D,
    ];

    this.fetchPlaceVacancyList();
    this.timer = setInterval(() => {
      this.fetchPlaceVacancyList();
    }, 30 * 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
});
