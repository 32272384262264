
import Vue, { PropType } from "vue";

type DisplayVacancyItem = "vacant" | "waiting" | "closed" | "tbc";
export type WaitingMinutesRange = { from: number; to: number };

export default Vue.extend({
  name: "vacancy-airport",
  props: {
    vacancyState: { type: String, required: true },
    waitingMinutesRange: { type: Object as PropType<WaitingMinutesRange>, required: false },
    language: { type: String, required: true },
  },
  computed: {
    displayVacancyItem(): DisplayVacancyItem | undefined {
      switch (this.vacancyState) {
        case "vacancy":
          return "vacant";
        case "waiting":
          return "waiting";
        case "closed":
          return "closed";
        default:
          return "tbc";
      }
    },
    showLargerText(): boolean {
      if (this.waitingMinutesRange === undefined) {
        return false;
      }
      return [1, 2].includes(this.digits(this.waitingMinutesRange.from));
    },
    showSmallerText(): boolean {
      if (this.waitingMinutesRange === undefined) {
        return false;
      }
      return this.digits(this.waitingMinutesRange.from) === 3;
    },
    displayWaitingMinuteRange(): string {
      if (this.waitingMinutesRange === undefined) {
        return "";
      }
      return `${this.waitingMinutesRange.from}-${this.waitingMinutesRange.to}`;
    },
  },
  methods: {
    digits(minutes: number): 1 | 2 | 3 {
      const digits: number = minutes.toString().length;
      if (digits <= 1) {
        return 1;
      } else if (digits >= 3) {
        return 3;
      } else {
        return 2;
      }
    },
  },
});
