
import Vue from "vue";

export default Vue.extend({
  name: "section-map",
  props: {
    language: { type: String as () => "ja" | "en", default: "en" },
  },
  computed: {
    imageSrc(): string {
      return this.language === "en" ? require("@/assets/map_en.png") : require("@/assets/map_ja.png");
    },
    anaInfoUrl(): string {
      return this.language === "en"
        ? "https://www.ana.co.jp/en/jp/domestic/departure/airport/map/hnd02_satellite.html"
        : "https://www.ana.co.jp/ja/jp/domestic/departure/airport/map/hnd02_satellite.html";
    },
  },
});
