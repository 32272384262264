import axios, { AxiosInstance, AxiosResponse } from "axios";
import { SignageAdapterApi } from "@vacancorp/signage.adapter.api.vacanservice.com";

const $http: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SIGNAGE_ADAPTER_API_BASE_URL,
    timeout: 60000,
});

export const getVacancyListByPlaceIdHashList = async (placeIdHashList: string[]): Promise<ResponsePlaceVacancy[]> => {
    if (placeIdHashList.length === 0) {
        return [];
    }
    return $http
        .get(`/v1/places/${placeIdHashList.join(",")}/vacancy`)
        .then((response: AxiosResponse) => response.data);
};

export interface ResponsePlaceVacancy {
    placeIdHash: string;
    status: string;
    displayVacancyText: SignageAdapterApi.Internationalization;
    vacancyCountText?: SignageAdapterApi.Internationalization;
    alertList: { type: VacancyAlert; startUnixtime?: number }[];
    appearance: Appearance;
}
type VacancyAlert = "invalidFacilityVacancy" | "directlyRelatedSensorStopped";
type Appearance = {
    textColor: string;
    backgroundColor: string;
};
